<template>
  <!-- Session expired dialog -->

  <q-dialog
    v-model="sessionExpiredDialog"
    persistent
  >
    <q-card :style="{ 'font-family': settings.typography }" >
      <q-card-actions align="center">
        <q-btn icon="error" color="orange" size="25px" flat />
      </q-card-actions>
      <q-card-section>
        <div class="text-h6 text-center">
          {{ $t('Login.session_expired_title') }}
        </div>
        <div class="text-center q-mt-md">
          {{ $t('Login.session_expired_description') }}
        </div>
      </q-card-section>
      <q-card-actions align="center">
        <q-btn
          class="btn-home"
          :label="$t('General.confirm')"
          :style="{ 'background-color': settings.color_accent }"
          @click="handleConfirm()"
          :loading="sessionExpiredLoading"
        />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script>
import { mapActions } from "vuex";

export default {
  data() {
    return {
      sessionExpiredDialog: false,
      sessionExpiredLoading: false
    };
  },
  props: {
    settings: {
      type: Object,
    },
  },
  methods: {
    ...mapActions("auth", ["logout"]),
    checkExpiredQueryParam() {
      if (Object.hasOwn(this.$route.query, "session_expired") && this.$route.meta.middleware.includes('auth')) {
        this.sessionExpiredDialog = true;
      }
    },
    handleConfirm() {
      this.sessionExpiredLoading = true;
      this.logout();
      if (this.settings.session_expired_redirect && this.settings.session_expired_redirect != '') {
        window.location.replace(this.settings.session_expired_redirect);
      } else {
        this.sessionExpiredDialog = false;
        this.sessionExpiredLoading = false;
        this.$router.push({ name: "Login"});
      }
    }
  },
  computed: {
    routeQuery() {
        return this.$route.query;
    },
  },
  watch: {
    routeQuery: function (val) {
      this.checkExpiredQueryParam();
    },
  },
  mounted() {
    this.checkExpiredQueryParam()
  }
};
</script>

<style lang="scss" scoped>
.q-card {
  min-width: 20rem;
  border-radius: 25px 25px 25px 0px;
}

.btn-home {
  border-radius: 25px 25px 25px 0px;
  height: 48px;
  margin-top: 10px;
  text-transform: capitalize;
  width: 100%;
  color: white;
}
</style>
